import { FC } from 'react';
import IconProps from './IconProps';

// LEGACY Icon, duplicated by `PrivacyByDesignIcon`
const PoliciesAndProceduresIcon: FC<IconProps> = (props) => {
  const { onClick, className, accentColor = '#C5A76B' } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5153 4.99218C21.5153 4.57698 21.2496 4.20836 20.8557 4.07706L11.8246 1.06667L2.79338 4.07706C2.39949 4.20836 2.1338 4.57698 2.1338 4.99218V12.9684C2.13084 14.8823 2.69616 16.754 3.75812 18.3463C4.82008 19.9386 6.33087 21.1798 8.099 21.9126L11.8246 23.4667L15.5501 21.9126C17.3183 21.1798 18.829 19.9386 19.891 18.3463C20.953 16.754 21.5183 14.8823 21.5153 12.9684V4.99218ZM19.3618 12.9684C19.3644 14.4566 18.9251 15.9122 18.0996 17.1505C17.274 18.3888 16.0994 19.3542 14.7246 19.9242L11.8246 21.1337L8.92451 19.9242C7.54972 19.3542 6.3751 18.3888 5.54955 17.1505C4.72401 15.9122 4.2847 14.4566 4.2873 12.9684V5.84744L11.8246 3.33502L19.3618 5.84744V12.9684Z"
        fill="currentColor"
      />
      <path
        d="M9.39892 11.1738C8.97869 10.7535 8.29735 10.7535 7.87711 11.1738C7.45688 11.594 7.45688 12.2754 7.87711 12.6956L10.3465 15.1649C10.5482 15.3669 10.8219 15.4805 11.1074 15.4808C11.2517 15.4783 11.3941 15.4469 11.526 15.3883C11.658 15.3297 11.7768 15.2452 11.8754 15.1398L16.523 10.1824C16.9295 9.74882 16.9073 9.06771 16.4734 8.66153C16.0393 8.25523 15.3579 8.27814 14.9522 8.7127L11.0822 12.8571L9.39892 11.1738Z"
        fill={accentColor}
      />
    </svg>
  );
};

export default PoliciesAndProceduresIcon;
